import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './scss/homepage.css';
import { getHomepageData } from '../../../Redux/action/SeekerAction';

const host = process.env.REACT_APP_API_SERVER;

const Homebanner = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomepageData());

    }, []);



    let video = document.querySelector("video");
    // if video exist -> play
    // if (video) {
    //     video.play()
    // }

    const homepageBannerData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.homepageDataList?.data?.bannerData) : []);

    // Carousel autoplay
    useEffect(() => {
        const carouselElement = document.querySelector('#carouselExampleIndicators');
        if (carouselElement) {
            const bootstrapCarousel = new window.bootstrap.Carousel(carouselElement, {
                interval: 5000,
                ride: 'carousel'
            });
        }
    }, [homepageBannerData]);


    return (
        <>
            {(homepageBannerData?.length !== 0) ? <section className='header-banner'>
                {/* <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-indicators">
                        {homepageBannerData?.map((_, index) => (
                            <button key={index} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"} aria-label={`Slide ${index + 1}`} />
                        ))}
                    </div>
                    <div className="carousel-inner">
                        {homepageBannerData?.map((data, index) => {

                            const mediaSrc = data.file_path ? `${host}/${data.file_path}` : "/assets/images/homebanner.png";
                           

                            return (
                                <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                    {data.file_type === "image" ? (
                                        <img src={mediaSrc} className='img-fluid' alt={data.title}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = "/assets/images/homebanner.png";
                                            }}
                                        />
                                    ) : data.file_type === "video" ? (
                                        <video className='img-fluid' autoPlay muted loop>
                                            <source src={mediaSrc} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : null}
                                    <div className='banner-text'>
                                        <h1>{data.title ? data.title : 'Connecting talent to opportunity'}</h1>
                                        {index == 1 && !localStorage.getItem("seeker_token") ?
                                            <Link to="/post-job" className='find-job-btn'>Post A Job</Link>
                                            :
                                            <Link to="/career-start-search" className='find-job-btn'>Find jobs</Link>
                                        }

                                    </div>
                                </div>
                            );
                        })}
                    </div>

                  
                </div> */}
            </section> : <section className="header-banner"><div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-label="Slide 1" aria-current="true"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" className=""></button>
                </div>
                <div className="carousel-inner"><div className="carousel-item carousel-item-next carousel-item-start">
                    <img src="/assets/images/homebanner.png" className="img-fluid" />
                    <div className="banner-text">
                        <h1>Connecting talent to  opportunity </h1>
                        <Link className="find-job-btn" to="/post-job">Post A Job</Link>
                    </div></div><div className="carousel-item active carousel-item-start">
                        <img src="/assets/images/homebanner1.png" className="img-fluid" />
                        <div className="banner-text">
                            <h1>You have the talent, We have the tools</h1>
                            <a className="find-job-btn" href="/career-start-search">Find a job</a>
                        </div>
                    </div>
                </div>
                {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button> */}
                {/* <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button> */}
            </div>
            </section>
            }
        </>
    );
};

export default Homebanner;
